import React, { useEffect, lazy } from "react";
import image from "./../assets/images/01.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function whatsApp_SignUp() {
  // Load Facebook SDK
  useEffect(() => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: "120049",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v19.0",
      });
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  // Facebook Login with JavaScript SDK
  function launchWhatsAppSignup() {
    FB.login(
      function (response) {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          // Use this token to call the debug_token API and get the shared WABA's ID
          console.log("AuthResponse:", response.authResponse);
          console.log("Response:", response);
          console.log("Access Token:", accessToken);
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "435407426126383", // configuration ID obtained in the previous step goes here
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true,
        extras: {
          feature: "whatsapp_embedded_signup",
          sessionInfoVersion: 2, //  Receive Session Logging Info
        },
        // extras: {
        //   setup: {
        //     // Prefilled data can go here
        //   },
        // },
      }
    );
  }
  return (
    <section>
      <div className="px-4 py-20 mx-auto max-w-7xl sm:px-6 md:px-12 lg:px-24 md:py-24 lg:pt-32">
        <div className="flex flex-wrap items-center mx-auto max-w-7xl">
          <div className="w-full lg:max-w-lg lg:w-1/2 rounded-xl">
            <div>
              <div className="relative">
                <div className="absolute top-0 rounded-full bg-purple-200 -left-4 w-72 h-72 mix-blend-multiply filter blur-3xl opacity-70 animate-blob"></div>
                <div className="absolute rounded-full bg-purple-200 -bottom-12 right-20 w-80 h-72 mix-blend-multiply filter blur-3xl opacity-70 animate-blob animation-delay-4000"></div>
                {/* <img src={image} alt="dd" /> */}
                <div className="object-cover object-center mx-auto rounded-lg  w-full h-auto lg:max-w-sm ">
                  <LazyLoadImage alt="hero" src={image} effect="blur" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start mt-12 mb-16 text-left lg:flex-grow lg:w-1/2 lg:pl-6 xl:pl-24 md:mb-0 xl:mt-0">
            <span className="mb-8 text-xs font-bold tracking-widest text-primary uppercase">
              Accept Bookings via WhatsApp
            </span>
            <h1 className="mb-8 text-4xl font-bold leading-none tracking-tighter text-gray-800  md:text-7xl lg:text-5xl">
              Unlock the Full Potential of Your Salon with WhatsApp and Glozea
            </h1>
            <p className="mb-8 text-base leading-relaxed text-left text-gray-500">
              Your clients can now book appointments directly through WhatsApp,
              making scheduling effortless. Manage all bookings seamlessly in
              our Glozea partner app, ensuring you stay organized and in
              control. No other app downloads required from your clints. Start
              simplifying your appointments process today!
            </p>
            <button
              onClick={launchWhatsAppSignup}
              viewport={{ once: true }}
              whileInView="animate"
              initial="initial"
              style={{
                backgroundColor: "#1877f2",
                border: 0,
                borderRadius: "4px",
                color: "#fff",
                cursor: "pointer",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: "16px",
                fontWeight: "bold",
                height: "40px",
                padding: "0 24px",
              }}
              className="focus:outline-none"
            >
              Login with Facebook
            </button>
            <p className="mb-8 text-base leading-relaxed text-left text-gray-500">
              *To start accepting appointments/bookings through WhatsApp, you
              must be a registered partner on Glozea. Please do not log in using
              the above Facebook button if you are not already a Glozea partner.
            </p>
          </div>
        </div>
      </div>
    </section>
    // <div>
    //   <h1>WhatsApp Sign Up</h1>
    //   <div className="flex justify-center items-center h-screen">
    //     <button
    //       onClick={launchWhatsAppSignup}
    //       viewport={{ once: true }}
    //       whileInView="animate"
    //       initial="initial"
    //       style={{
    //         backgroundColor: "#1877f2",
    //         border: 0,
    //         borderRadius: "4px",
    //         color: "#fff",
    //         cursor: "pointer",
    //         fontFamily: "Helvetica, Arial, sans-serif",
    //         fontSize: "16px",
    //         fontWeight: "bold",
    //         height: "40px",
    //         padding: "0 24px",
    //       }}
    //       className="focus:outline-none"
    //     >
    //       Login with Facebook
    //     </button>
    //   </div>
    // </div>
  );
}

export default whatsApp_SignUp;
