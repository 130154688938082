import React, { useState } from 'react';
import { motion, useScroll, useMotionValueEvent } from 'framer-motion'
import {
    Link,
} from "react-router-dom";
import { scrollToTop } from '../utils/utils';
import logo from './../assets/images/logo.svg';

const Header = () => {


    let Links = [
        { name: "Home", link: "/", key: 1 },
        { name: "Partner", link: "/partner", key: 2 },
        { name: "Vision", link: "/vision", key: 3 },
        { name: "ContactUs", link: "/contactus", key: 4 },
    ];

    //mobile sr section 
    let [open, setOpen] = useState(false);
    const onNavClick = () => {
        setOpen(!open);

    };





    // hiding nav bar on scroll
    const padding = 150;
    const { scrollY } = useScroll();
    const [hidden, setHidden] = useState(false);
    useMotionValueEvent(scrollY, "change", (latest) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > padding) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    });

    return (
        <motion.nav
            variants={{
                visible: { y: 0 },
                hidden: { y: "-100%" }
            }}
            animate={hidden ? "hidden" : "visible"}
            transition={{ duration: 0.35, ease: "easeInOut" }}
            className='w-full fixed top-0 left-0 bg-white z-50 '>
            <div className='md:flex justify-around py-4 md:px-10 px-7'>
                {/* logo section */}
                <Link className="flex items-center">
                    <img className="mr-3 h-6 w-full sm:h-9" alt="Logo" src={logo} to="/" onClick={scrollToTop} />
                </Link>
                {/* Menu icon */}
                <div onClick={() => setOpen(!open)} className='absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7'>
                    {
                        open ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                            : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-gray-700">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
                            </svg>

                    }
                </div>
                {/* linke items */}
                <ul className={`md:flex md:items-center md:pb-0 pb-4 absolute md:static bg-white md:z-auto -z-10 left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-12' : 'top-[-490px]'}`}>
                    {
                        Links.map((navLink, index) => (
                            <li onClick={() => setOpen(!open)} key={index} className='md:ml-8 md:my-0 my-2 font-semibold no-underline'>
                                <Link to={navLink.link} className='block py-2 pr-4 pl-3 text-gray-700 lg:hover:bg-transparent lg:border-0 hover:text-primary lg:p-0 ' onClick={scrollToTop}>{navLink.name}</Link>
                            </li>))
                    }
                </ul>
            </div>
        </motion.nav>
    );
};

export default Header;
