const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

const scrollToDown = () => {
    window.scrollTo({
        top: 2000,
        behavior: 'smooth',
    });
};


export { scrollToTop, scrollToDown };