import React from 'react'
import { Circles } from 'react-loader-spinner'

function Loader() {
    return (
        <div className={'flex h-screen justify-center items-center'}>
            <Circles
                height="80"
                width="80"
                color="#ed145b"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    )
}

export default Loader;
