// import React from 'react'
// class ErrorBoundary extends React.Component {
//     state = { hasError: false };


//     static getDerivedStateFromError(error) {
//         return { hasError: true };
//     }

//     componentDidCatch(error, errorInfo) {
//         // You can log the error here
//         console.error(error, errorInfo);
//     }

//     render() {
//         if (this.state.hasError) {
//             return this.props.fallback || <h1>Something went wrong.</h1>

//         }

//         return this.props.children;
//     }
// }

// export default ErrorBoundary;


import React from 'react';

class ErrorBoundary extends React.Component {
    // Equivalent to:
    // const [hasError, setHasError] = useState(false);
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error);
        console.info({ errorInfo });
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback || <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

