import { lazy, Suspense } from "react";
// import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Header from "./Components/header.jsx";
import Loader from "./Components/Loader.jsx";
import ErrorBoundary from "./Components/ErrorBoundary";
const Footer = lazy(() => import("./Components/footer.jsx"));
const CookieBanner = lazy(() => import("./Components/cookiebanner.jsx"));
const Home = lazy(() => import("./pages/home.jsx"));
const PrivacyPolicy = lazy(() => import("./pages/privacypolicy.jsx"));
const Termsofservice = lazy(() => import("./pages/termsofservice.jsx"));
const ContactUs = lazy(() => import("./pages/contactus.jsx"));
const Cookie = lazy(() => import("./pages/cookie.jsx"));
const License = lazy(() => import("./pages/license.jsx"));
const Partner = lazy(() => import("./pages/partner.jsx"));
const Error = lazy(() => import("./pages/errorpage.jsx"));
const Vision = lazy(() => import("./pages/Vision.jsx"));
import Whats from "./pages/whatsApp_SignUp.jsx";
const whatsApp_SignUp = lazy(() => import("./pages/whatsApp_SignUp.jsx"));
const DeleteMyAccount = lazy(() => import("./pages/DeleteMyAccount.jsx"));
const MyToastContainer = lazy(() =>
  import("./Components/MyToastContainer.jsx")
);

const Dashboard = () => {
  return (
    <div>
      <Suspense fallback={<Loader />}>
        <Header />
        <Outlet />
        <Footer />
      </Suspense>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/privacypolicy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/termsofservice",
        element: <Termsofservice />,
      },
      {
        path: "/contactus",
        element: <ContactUs />,
      },
      {
        path: "/partner",
        element: <Partner />,
      },
      {
        path: "/cookie",
        element: <Cookie />,
      },
      {
        path: "/vision",
        element: <Vision />,
      },
      {
        path: "/license",
        element: <License />,
      },
      {
        path: "/deletemyaccount",
        element: <DeleteMyAccount />,
      },
      {
        path: "/meta/whatsapp_integration",
        element: <Whats />,
      },
    ],
  },
  {
    path: "*",
    element: <Error className="bg-white" />,
  },
]);

function App() {
  return (
    <>
      <ErrorBoundary>
        <RouterProvider router={router} />
        <CookieBanner />
        <Suspense fallback={<Loader />}>
          <MyToastContainer />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

export default App;
